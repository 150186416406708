
import './App.css';

function App() {

  return (
      <div className="container">

          <div className="header">
              <h2>Cyber Inquirer</h2>
              <p className="subtitle">Become smarter every day with challenging quizzes!</p>

                  <a href="https://t.me/CyberInquirerBot/mainapp" target={"_blank"} rel="noreferrer"
                     className="cta-button">Play Now</a>
          </div>

          <div className="about" id="about">
              <h2>About Cyber Inquirer</h2>
              <p>Cyber Inquirer is a trivia game designed to make you smarter while having fun. With a new set of
                  questions
                  every day, you’ll keep learning and challenging yourself. Whether you are a learner or a trivia
                  master,
                  Cyber Inquirer has something for everyone!</p>

              <a href="/docs/whitepaper_1.pdf" target={"_blank"} className="cta-button">
                  Download Whitepaper
              </a>
          </div>

          <div className="join" id="join">
              <h2>Join the Challenge</h2>
              <p>Click the button below to join our community!</p>
              <a href="https://t.me/cyberinquirerchannel" target={"_blank"} rel="noreferrer" className="cta-button">Join
                  on Telegram</a>
          </div>

          {/*<div className="contact">*/}
          {/*    <h2>Contact Us</h2>*/}
          {/*    <p>If you have any questions or suggestions, feel free to reach out!</p>*/}
          {/*    <form*/}
          {/*        onSubmit={(e) => {*/}
          {/*            e.preventDefault();*/}
          {/*            const name = encodeURIComponent(e.target.name.value);*/}
          {/*            const email = encodeURIComponent(e.target.email.value);*/}
          {/*            const message = encodeURIComponent(e.target.message.value);*/}

          {/*            const mailtoLink = `mailto:info@cyberinquirer.xyz?subject=Message%20from%20${email}&body=Name:%20${name}%0AEmail:%20${email}%0AMessage:%20${message}`;*/}

          {/*            window.location.href = mailtoLink;*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <input type="text" name="name" placeholder="Your Name" required/>*/}
          {/*        <input type="email" name="email" placeholder="Your Email" required/>*/}
          {/*        <textarea name="message" placeholder="Your Message" required></textarea>*/}
          {/*        <button type="submit">Send Message</button>*/}
          {/*    </form>*/}
          {/*</div>*/}

      </div>
  );
}

export default App;

